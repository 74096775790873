import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import HeroCarousel from 'react-hero-carousel'
import { CheckIcon } from '@heroicons/react/outline'

import Meta from '../components/Meta'
import Layout from '../components/Layout'

function Page({ location }) {
  const { shareImage } = useStaticQuery(graphql`
    query {
      shareImage: file(relativePath: { eq: "services/lawn-banner-1.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <>
      <Meta
        title="Lawn Installation"
        description="Expert advice on reinstating or caring for your lawn. We work at both a domestic and commercial scale and take on lawn installations of all sizes."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="lg:flex justify-between bg-gray-lightest border-b-8 border-green">
          <div className="grid justify-center content-center mx-auto py-12 px-8 lg:py-16 lg:px-12">
            <h2 className="text-4xl font-extrabold sm:text-5xl text-center">
              <span className="uppercase">Lawns</span>
              <br />
              <small className="text-gray-light">with</small>
              <br />
              Pro<span className="text-green">Gro</span>
            </h2>
          </div>
          <div className="flex-1 max-w-7xl">
            <StaticImage
              src="../images/services/lawn-banner-1.webp"
              alt="Featured lawn installation work"
              className="aspect-w-16 aspect-h-7"
              aspectRatio={16 / 7}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
          </div>
        </section>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                Lawn Installation
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <StaticImage
                      src="../images/services/lawn-feature-1.webp"
                      alt="Featured work"
                      className="aspect-w-12 aspect-h-7"
                      aspectRatio={12 / 7}
                      placeholder="blurred"
                      layout="fullWidth"
                      transformOptions={{ cropFocus: 'entropy' }}
                    />
                    <figcaption className="mt-3 flex text-sm text-gray-light" />
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    There is no substitute that can beat a beautiful and healthy
                    looking lawn. ProGro Landscapes are experts in lawn
                    installation and offer expert advice on reinstating or
                    caring for your lawn. We work at both a domestic and
                    commercial scale and take on lawn installations of all
                    sizes.
                  </p>
                </div>
                <div className="mt-5 prose prose-indigo text-gray-light mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <p>
                    We believe that &apos;to get the green right, you first need
                    to get the brown right&apos;, which means that we look at
                    the condition of the existing soil and will condition it
                    where necessary to provide optimum long term results for the
                    health of the lawn.&nbsp;
                  </p>
                  <p>
                    It is important to know that not all turf is the same, and
                    our specialist suppliers provide us with the highest quality
                    of turf. In addition,&nbsp;we will occasionally&nbsp;tailor
                    our orders of turf and seed to select appropriately for the
                    intended use and environment of the lawn.&nbsp;
                  </p>
                </div>
              </div>
            </div>
            <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
              <div className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray">
                    Ground Preparation
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-light">
                  Sod cutting, Rotovation, topsoiling, and leveling.
                </dd>
              </div>
              <div className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray">
                    Turfing
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-light">
                  Installation of top quality turf. Grass is greener with ProGro
                  Landscapes.
                </dd>
              </div>
              <div className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray">
                    Lawn Seeding
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-light">
                  Precision lawn seeding offered for long term great results.
                </dd>
              </div>
              <div className="relative">
                <dt>
                  <CheckIcon
                    className="absolute h-6 w-6 text-green"
                    aria-hidden="true"
                  />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray">
                    Wildflower Turf
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-light">
                  Installation of wildflower turf and seed for low maintenance
                  areas and to improve wildlife.
                </dd>
              </div>
            </dl>
          </div>
        </section>
        <section className="relative h-16 sm:h-24 lg:h-48 xl:h-96">
          <HeroCarousel height="100%">
            <StaticImage
              src="../images/services/lawn-banner-2.webp"
              alt="Featured lawn slide"
              aspectRatio={16 / 4}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
            <StaticImage
              src="../images/services/lawn-banner-3.webp"
              alt="Featured lawn slide"
              aspectRatio={16 / 4}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
            <StaticImage
              src="../images/services/lawn-banner-4.webp"
              alt="Featured lawn slide"
              aspectRatio={16 / 4}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
            <StaticImage
              src="../images/services/lawn-banner-5.webp"
              alt="Featured lawn slide"
              aspectRatio={16 / 4}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
          </HeroCarousel>
        </section>
        <section className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray sm:py-24 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-gray">
              Frequently asked questions
            </h2>
            <div className="mt-6 pt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
                <div className="relative">
                  <dt className="text-lg leading-6 font-medium text-gray">
                    What to do about watering new turf?
                  </dt>
                  <dd className="mt-2 text-base text-gray-light">
                    The first 2/3 weeks are crucial to keep regularly watered,
                    but it is important to keep an eye on it for the first 2/3
                    months, depending on how dry the weather is. The best times
                    to water turf is either first thing in the morning, or
                    evening.
                  </dd>
                </div>
                <div className="relative">
                  <dt className="text-lg leading-6 font-medium text-gray">
                    How long does it take for turf to root?
                  </dt>
                  <dd className="mt-2 text-base text-gray-light">
                    After 2/3 weeks, the turf will have formed shallow roots
                    that start to cling to the top surface of the top soil. By
                    6/8 weeks, the turf will have deeper roots.
                  </dd>
                </div>
                <div className="relative">
                  <dt className="text-lg leading-6 font-medium text-gray">
                    When should I mow my newly laid lawn?
                  </dt>
                  <dd className="mt-2 text-base text-gray-light">
                    Wait until the lawn has rooted well. This is likely to be 6
                    weeks or so after the lawn was laid. Be sure to cut the lawn
                    at a medium-to high height. Short lawns dry out quicker
                    which is to be avoided with a newly laid lawn. On tyhe flip
                    side, don&apos;t let the grass get too long before cutting.
                    Cutting little and often is key to a beautiful lawn.
                  </dd>
                </div>
                <div className="relative">
                  <dt className="text-lg leading-6 font-medium text-gray">
                    How to keep the lawn looking good in the future?
                  </dt>
                  <dd className="mt-2 text-base text-gray-light">
                    Regularly mowing and watering when needed is the best care
                    you can give, but there are some other things you can do to
                    keep your lawn looking great. It is important to feed and
                    weed the lawn twice a year (autumn/spring), this will keep
                    the lawn looking lush and gives the soil nutrition.
                    <br />
                    <br />
                    If the lawn has a lot of traffic on it e.g. playing
                    children, the ground will become compacted over time, so
                    aerating the lawn once a year is something to consider, this
                    also gives the ground some extra nitrogen. If the lawn is in
                    an area susceptible to moss, spraying the lawn with an iron
                    based moss killer and scarifying the lawn will stop the moss
                    from suffocating the grass.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
